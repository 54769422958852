import { createStore } from 'vuex'

const modules = {}
const files = require.context('./modules', false, /\.js$/)
files.keys().forEach((key) => {
    modules[key.replace(/(modules|\/|\.|js)/g, '')] = {
        ...files(key).default,
        namespaced: true,
    }
})

export default createStore({
    modules,
})

