<template>
  <img v-if="loadUserHeadStatus && userInfo.photo" :src="userHeader" @error="userLoadError" alt="">
  <img v-else src="@/assets/user-default-head.png" style="margin-right: 12px;"  alt=""/>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      loadUserHeadStatus:true
    }
  },
  computed:{
    ...mapGetters('user', ['userInfo']),
    userHeader(){
      try{
        if(this.userInfo.photo.indexOf('http') !== -1) return this.userInfo.photo
        if(this.userInfo.photo.indexOf('@avatar') !== -1){
          const index = this.userInfo.photo.indexOf('@avatar')
          const headImgStr = this.userInfo.photo.substring(index + 7)
          return require(`../assets/defaultAvatar${this.userInfo.defaultAvatarType}${headImgStr}`)
        }
        return this.userInfo.photo
      }catch (e) {
        console.log(e)
        return ''
      }
    }
  },
  methods: {
    userLoadError(){
      console.log('heaer load err')
      this.loadUserHeadStatus = false
    }
  }
}
</script>

<style lang="less" scoped>
img {
  width: 38px;
  flex: 0 0 38px;
  height: 38px;
  border-radius: 100%;
  margin-right: 12px;
}
</style>