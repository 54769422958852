import service from "@/utils/ajax"

//获取授权
export const accessAuth = (data, appkey)=>{
    return service({
        url:"/v1/serviceApi/accessAuth",
        method:"POST",
        headers:{
            appkey
        },
        data
    })
}

//文件上传
export const uploadFile = (data)=>{
    return service({
        url:"/v1/file/uploadFile",
        method:"POST",
        headers:{
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

// 获取工单详情
export const orderDetails = (params)=>{
    return service({
        url:"/v1/workOrder/details",
        method:"GET",
        params
    })
}
