<script setup>

</script>

<template>
  <div class="header">
    <span class="icon back">
      <img src="@/assets/back-icon.png" alt="">
    </span>
    <span class="title">客服</span>
    <span class="icon">
      <img src="@/assets/jl-icon.png" alt="">
    </span>
  </div>
</template>

<style scoped lang="less">
.header {
  height: 42px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
}
.icon{
  flex: 0 0 56px;
  width: 56px;
  height: 32px;
  display: flex;
  &.back{
    width: 16px;
    height: 16px;
  }
}
.title{
  flex: 1;
  text-align: center;
  color: #121C32;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
}
</style>
