<template>
  <div class="loading" v-if="!loadLocal">
    <var-loading type="circle" color="#252c21" description="加载中..."/>
  </div>
  <router-view v-if="loadLocal"/>
</template>

<script setup>

//import {useStore} from "vuex";
//import localforage from "localforage";
import {onMounted, ref} from "vue";

//const store = useStore()
const loadLocal = ref(false)

onMounted(async ()=>{
  loadLocal.value = true
})
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
.loading {
  height: 100vh;
  display: flex;
  align-self: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
</style>
