<script setup>
import {computed, defineProps} from "vue"
import {useStore} from "vuex";
import {getCurrentDate} from "@/utils/func";

const props = defineProps({
  chatItem:{
    type:Object,
    default:()=>{}
  }
})
const store = useStore()
const SOCKETIO = computed(()=> store.state.socketMsg.socketIO)

const checkLssues = (item)=>{
  SOCKETIO.value.emit('hotIssues', item.title)
}
</script>

<template>
  <div class="chat-text">
    <div class="kefu-head">
      <img src="@/assets/kefu-default-head.png" alt=""/>
    </div>
    <div class="msg">
      <p class="time">{{getCurrentDate(props.chatItem.msgTime)}}</p>
      <div class="msg-text">
        <span class="title">热门问题</span>
        <div class="hot-list" v-for="item of props.chatItem" :key="item._id" @click="checkLssues(item)">
          <p class="hot-item">
            <span>{{item.title}}</span>
            <img src="@/assets/arrow1_right_disabled.png" alt="">
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.chat-text{
  display: flex;
  padding-left: 20px;
  padding-top: 20px;
}
.kefu-head{
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
  img{
    width: 100%;
    height: 100%;
  }
}

.msg{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 500px;
  .msg-text{
    background: #fff;
    border-radius: 10px;
    max-width: 80%;
    padding: 10px;
    min-height: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  .time{
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    margin-bottom: 6px;
  }
  span{
    color: #1C212A;
    font-size: 14px;
    font-weight: 500;
  }
}
.title{
  margin-bottom: 6px;
}
.hot-list{
  .hot-item{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E5E6EB;
    padding: 8px 0;
    &:last-child{
      border: none;
    }
    span{
      flex: 1;
    }
    img{
      width: 14px;
      height: 14px;
      margin-left: 20px;
    }
  }
}
</style>
