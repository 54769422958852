import store from "@/store"
import {toRaw} from "vue"
import localforage from "localforage"

const state = () => ({
    socketIO:null,
    connectStatus:false, // 连接状态
    msgList:[], // 消息列表,定义：{msgKey:[msgItem]}
    noReadList:{}, // 未读消息msgKey列表
})

const getters = {
    getMsgList: (state) => (msgKey) => {
        const msgList = state.msgList[msgKey]
        if(!msgList || !state.talkList.length) {
            return []
        }else{
            const cloneData = JSON.parse(JSON.stringify(msgList))
            return cloneData.sort((a, b) => new Date(a.msgTime) - new Date(b.msgTime));
        }
    },
    getlastMsg:(state)=>(msgKey)=>{
        const msgList = state.msgList[msgKey]
        if(!msgList) return {}
        const cloneData = JSON.parse(JSON.stringify(msgList))
        cloneData.sort(function(a, b) {
            return (b.msgTime?new Date(b.msgTime):new Date()) - (a.msgTime?new Date(a.msgTime):new Date())
        });
        return cloneData[0]
    },
    getServiceList: (state)=>{
        const kefuInfo = store.getters['user/kefuInfo']
        return state.serviceList.filter(item=> item.serviceId !== kefuInfo._id)
    },
    getUserNoReadNum: (state)=>(talkItem)=>{
        if(!talkItem.appKey || !talkItem.user) return 0
        const userNoRead = state.noReadList[`${talkItem.appKey}-${talkItem.user}`]
        return userNoRead?userNoRead:[]
    }
}

const mutations = {
    setConnectStatus(state, status) {
        state.connectStatus = status
    },
    setMsgList(state, msg){
        if(!msg) return
        state.msgList.push(msg)
        const msgList = toRaw(state.msgList).filter(item=> item.msgType !== 'hotIssues')
        localforage.setItem('msgList-client', msgList)
    },
    setSocketIO(state, io){
        console.log(io)
        state.socketIO = io
    },
    resetMsgList(state, msgList){
        state.msgList = msgList
    },
    // 存储未读消息ID
    setNoReadList(state, msg){
        if(!msg.msgKey || !msg.formID) return
        const noReadList = toRaw(state.noReadList)
        const userNoReadList = noReadList[msg.formID]
        if(userNoReadList){
            userNoReadList.push(msg.msgKey)
            state.noReadList[msg.formID] = userNoReadList
        }else{
            state.noReadList[msg.formID] = [msg.msgKey]
        }
        localforage.setItem(`noReadList-client`, toRaw(state.noReadList))
    },
    resetNoReadList(state, noRead){
        state.noReadList = noRead
    },
    clearNoReadList(state){
        state.noReadList = {}
        localforage.removeItem('noReadList-client')
    },
    // 收到已读推送-设置消息已读状态
    setMsgRead(state, {msgKey, readStatus}){
        console.log(msgKey)
        if(msgKey){
            if(Array.isArray(msgKey)){
                for (let i = 0; i < msgKey.length; i++) {
                    const msgKeyItem = msgKey[i]
                    const findIndex = state.msgList.findIndex(item=> item.msgKey === msgKeyItem)
                    const cloneData = JSON.parse(JSON.stringify(state.msgList[findIndex]))
                    cloneData.readStatus = readStatus
                    state.msgList.splice(findIndex, 1, cloneData)
                }
            }else{
                const findIndex = state.msgList.findIndex(item=> item.msgKey === msgKey)
                const cloneData = JSON.parse(JSON.stringify(state.msgList[findIndex]))
                cloneData.readStatus = readStatus
                state.msgList.splice(findIndex, 1, cloneData)
            }
            localforage.setItem('msgList-client', toRaw(state.msgList))
        }
    },
    // 邀请评价状态变更
    setServiceRating(state, {msgKey, score}){
        if(!msgKey) return
        const findIndex = state.msgList.findIndex(item=> item.msgType === 'invitationEvaluation' && item.msgKey === msgKey)
        if(findIndex !== -1){
            const cloneMsg = JSON.parse(JSON.stringify(state.msgList[findIndex]))
            cloneMsg.evalStatus = 1
            cloneMsg.score = score
            state.msgList.splice(findIndex, 1, cloneMsg)
            localforage.setItem('msgList-client', toRaw(state.msgList))
        }
    }
}

const actions = {}

export default {state, getters, mutations, actions}
