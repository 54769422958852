const state = () => ({
    userInfo: null,
    uiserImToken: '',
    identity:'',
    kfMenu:[],
})

const getters = {
    userInfo: (state) => {
        if (state.userInfo) return state.userInfo
        const userInfo = localStorage.getItem('userInfo')
        if (userInfo) return JSON.parse(userInfo)
        return null
    },
    uiserImToken: (state) => {
        if (state.uiserImToken) return state.uiserImToken
        const uiserImToken = localStorage.getItem('uiserImToken')
        if (uiserImToken) return uiserImToken
        return ''
    },
}

const mutations = {
    setUserInfo(state, info) {
        localStorage.setItem('userInfo', JSON.stringify(info))
        state.userInfo = info
    },
    setUiserImToken(state, token) {
        localStorage.setItem('uiserImToken', token)
        state.uiserImToken = token
    }
}

export default {state, mutations, getters}
