import {createRouter, createWebHashHistory} from 'vue-router'
import Home from '../views/Home.vue'
import WorkOrder from "../views/workOrder.vue"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/bind-email',
    name: 'bind-email',
    component: () => import('../views/bind-email.vue')
  },
  {
    path: '/bind-phone',
    name: 'bind-phone',
    component: () => import('../views/bind-phone.vue')
  },
  {
    path: '/reset-pwd',
    name: 'reset-pwd',
    component: () => import('../views/reset-pwd.vue')
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('../views/result.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/account.vue')
  },
 
  {
    path: '/workOrder',
    name: 'WorkOrder',
    component: WorkOrder
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
