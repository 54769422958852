<script setup>
import {io} from "socket.io-client";
import {WS_URL} from "@/config";
import {Snackbar} from '@varlet/ui'
import {accessAuth, uploadFile} from "@/api/base";
import {ref, onMounted, computed, watch, onUnmounted, toRaw} from "vue";
import {useStore} from "vuex";
import MainHeader from "@/components/mainHeader.vue";
import ChatButton from "@/components/chatButton.vue";
import {useRoute} from "vue-router";
import ChatTextItem from "@/components/chatTextItem.vue";
import {v4 as uuidv4} from "uuid";
import HotLssues from "@/components/hotLssues.vue";
import {removeSpaces} from "@/utils/func";
import ChatImgItem from "@/components/chatImgItem.vue";
import EvaluateTemp from "@/components/evaluateTemp.vue";
import localforage from "localforage";
import { Dialog } from '@varlet/ui'
import { useRouter } from 'vue-router'

const loadingStatus = ref(false)
const store = useStore()
const textareaRef = ref()
const msg = ref('')
const msgList = computed(()=> store.state.socketMsg.msgList)
const userInfo = computed(()=> store.getters['user/userInfo'])
const SOCKETIO = computed(()=> store.state.socketMsg.socketIO)
const noReadList = computed(()=>store.state.socketMsg.noReadList)
const serviceInfo = ref(null)
const chatWindow = ref()
const pageVisibility = ref(true)
const toolsShow = ref(false)
const fileEl = ref()
const showLoading = ref(false)
const firstMsgNum = ref(0)
const conversation_id = ref('')
const autoAnswerStatus  = ref(1)
const router = useRouter()
const route = useRoute()

// 跳转至账号找回
const goAccount = ()=>{
  const searchParams = route.query
  const appkey = searchParams.appkey
  router.push(`/account?appkey=${appkey}`)
}


onMounted(() => {
  document.addEventListener("visibilitychange", pageShow);
  initSocket()
})

onUnmounted(()=>{
  document.removeEventListener("visibilitychange", pageShow);
})

// 监听消息队列，滚动到底部
watch(msgList,()=>{
  let scrollElem = chatWindow.value;
  if(!scrollElem) return
  setTimeout(()=>{
    scrollElem.scrollTo({
      top: scrollElem.scrollHeight,
      behavior: 'smooth'
    });
  },200)
},{ deep: true })

// 限制输入长度，textarea高度随着输入自动增高
function inputChange() {
  let textarea = textareaRef.value
  const height = textarea.scrollHeight;
  if(msg.value.length >= 200){
    Snackbar({
      content: "最多允许输入200个字符！",
      duration: 1000,
      type: "warning"
    })
    msg.value = msg.value.substring(0,200)
    return
  }
  if (height >= 200) {
    textarea.style.height = 'auto';
    textarea.style.height = height + "px";
  }
}
// 监听页面可见和不可见
const pageShow = ()=> {
  if (document.hidden) {
    console.log("页面不可见");
    pageVisibility.value = false
  } else {
    console.log("页面可见");
    pageVisibility.value = true
    setRead() // 设置缓存中未读消息为已读
  }
}
// 获取授权-初始化socketio
const initSocket = async () => {
  const searchParams = useRoute().query
  console.log(searchParams)
  if(!searchParams.sourceId){
    searchParams.sourceId = uuidv4()
    searchParams.sourceName = '游客'
  }
  if(!searchParams.appkey){
    Snackbar({
      content: "应用KEY异常！",
      duration: 1000,
      type: "warning"
    })
    return
  }
  if (searchParams.sourceId && searchParams.sourceName && searchParams.appkey) {
    const sourceId = await localforage.getItem('sourceId')
    console.log(sourceId, searchParams.sourceId)
    if(sourceId && sourceId !== searchParams.sourceId){
      setTimeout(async ()=>{
        await localforage.removeItem('msgList-client')
        await localforage.removeItem('noReadList-client')
        store.commit('socketMsg/setMsgList', [])
        store.commit('socketMsg/clearNoReadList', [])
      }, 500)
    }else{
      // 消息缓存写入state
      const msgList = await localforage.getItem('msgList-client')
      if(msgList && msgList.length) store.commit('socketMsg/resetMsgList', msgList)
      // 未读消息msgKey列表缓存写入state
      const noReadList = await localforage.getItem('noReadList-client')
      if(noReadList) store.commit('socketMsg/resetNoReadList', noReadList)
    }
    try {
      const authInfo = await accessAuth({
        sourceId: searchParams.sourceId,
        sourceName: searchParams.sourceName,
        sourcePhoto: searchParams.sourcePhoto || ''
      }, searchParams.appkey)
      if (authInfo.code === 0) {
        store.commit('user/setUserInfo', authInfo.data)
        store.commit('user/setUiserImToken', authInfo.token)
        const socket = io(`${WS_URL}?token=${authInfo.token}`);
        socket.on("connect", () => {
          console.log('connect ', socket.id);
        });
        socket.io.on("connect_error", () => {
          Snackbar({
            content: "聊天服务连接失败！",
            duration: 1000,
            type: "warning"
          })
        });
        socket.on("connection", e => {
          console.log('connection', e);
          if (e.code === 0) {
            loadingStatus.value = true
            store.commit('socketMsg/setSocketIO', socket)
            if(e.autoAnswerStatus === 1) socket.emit('hotIssues')
            localforage.setItem('sourceId', searchParams.sourceId)
            autoAnswerStatus.value = e.autoAnswerStatus
          } else {
            Snackbar({
              content: e.msg,
              duration: 1000,
              type: "warning"
            })
          }
        });
        // 收到消息
        socket.on("msg", async e => {
          console.log('msg', e);
          if(e.code && e.code === 201){
            store.commit('socketMsg/setMsgRead', {
              msgKey: e.msgKey,
              readStatus: 2
            })
            return
          }
          if(e.code && e.code === 200){
            const actions = {
              confirm: () => {
                SOCKETIO.value.emit('closeTalk',{
                  userId: serviceInfo.value._id,
                })
                serviceInfo.value = null
                firstMsgNum.value = 0
              },
            }
            actions[await Dialog('客服不在线，是否结束对话？')]()
            return
          }
          if (e.formID) {
            store.commit('socketMsg/setMsgList', e)
            // 回复收到
            if(e.msgKey){
              socket.emit('receivedMsg', {
                msgKey: e.msgKey
              })
            }
            // 设置已读
            if (pageVisibility.value) { // 页面可见直接回复已读
              socket.emit('setRead', {
                appKey: userInfo.value.appKey, // 应用appKey，必传 641c1076f22a3b75b99033d8
                user: e.formID, // 要设置消息为已读的用户ID，取对话列表返回user字段，和客服对话无对话列表时传消息中的formID
                msgKeyList: [e.msgKey], // 必传。发送消息时生成得uuid.如果没有msgKey不能实现消息已读未读。
                isService: 1, // 是否和客服沟通（和客服沟通必传1，和用户聊天可不传）
              })
            } else { // 页面不可见缓存未读消息msgKye,可见后批量回复已读
              store.commit('socketMsg/setNoReadList', e)
            }
          }
        });
        // 断开连接
        socket.on("disconnect", () => {
          console.log('disconnect', socket.id);
        });
        // 在线客服
        socket.on('onlineService', value=>{
          console.log('onlineService',value)
          if(value.code === 0){
            store.commit('socketMsg/setMsgList', {
              msgType: 'tips',
              msg: `客服${value.data.nickname} 为您服务`,
              msgTime: Date.now()
            })
            serviceInfo.value = value.data
          }else{
            store.commit('socketMsg/setMsgList', {
              msgType: 'tips',
              msg: value.msg,
              msgTime: Date.now()
            })
          }
        })
        // 热门问题
        socket.on('hotIssues', value=>{
          console.log('hotIssues',value)
          if(value.code === 0){
            if(value.conversation_id){
              conversation_id.value = value.conversation_id
            }
           if(value.data === null){
             store.commit('socketMsg/setMsgList', {
               msgType: 'hotIssues',
               msg: value.data
             })
             const inMsg = {
               appKey: userInfo.value.appKey,
               msg: value.msg,
               msgTime: Date.now(),
               msgType: 1,
             }
             store.commit('socketMsg/setMsgList', inMsg)
           }else{
             if(value.data.conversation_id) conversation_id.value = value.data.conversation_id
             store.commit('socketMsg/setMsgList', {
               msgType: 1,
               msg: value.data.content,
               msgTime: Date.now()
             })
           }
          }
        })
        // 回复收到监听
        socket.on('receivedMsg',value=>{
          console.log('receivedMsg',value)
        })
        // 已读监听
        socket.on('msgRead',value=>{
          console.log('msgRead',value)
          store.commit('socketMsg/setMsgRead', {
            msgKey: value.msgKeyList,
            readStatus: 3
          })
        })
        // 邀请评价监听
        socket.on('invitationEvaluation',value=>{
          console.log('invitationEvaluation',value)
          if(serviceInfo.value){
            store.commit('socketMsg/setMsgList', {
              msgType: 'invitationEvaluation',
              msgTime: Date.now(),
              serviceInfo: toRaw(serviceInfo.value),
              evalStatus:0, // 是否已评论
              msgKey: uuidv4()
            })
          }
        })
        // 评价返回监听
        socket.on('serviceRating',value=>{
          console.log('serviceRating',value)
          if(value.msgKey) store.commit('socketMsg/setServiceRating', {
            msgKey: value.msgKey,
            score: value.data.score||null
          })
        })
        // 结束对话监听
        socket.on('closeTalk',value=>{
          console.log('closeTalk',value)
          store.commit('socketMsg/setMsgList', {
            msgType: 'tips',
            msg: '客服关闭对话，对话已结束。',
            msgTime: Date.now()
          })
          serviceInfo.value = null
          firstMsgNum.value = 0
        })
        // 客服转接监听
        socket.on('switchService',value=>{
          console.log('switchService',value)
          store.commit('socketMsg/setMsgList', {
            msgType: 'tips',
            msg: value.msg,
            msgTime: Date.now()
          })
          serviceInfo.value = value.service
        })
      } else {
        Snackbar({
          content: "授权失败！",
          duration: 1000,
          type: "warning"
        })
      }
    } catch (e) {
      Snackbar({
        content: "授权失败！",
        duration: 1000,
        type: "warning"
      })
    }
  } else {
    Snackbar({
      content: "授权失败！",
      duration: 1000,
      type: "warning"
    })
  }
}
// 消息发送
const sendMsg = ()=>{
  const msgText = removeSpaces(msg.value)
  if(!msgText){
    Snackbar({
      content: "请输入消息文字！",
      duration: 1000,
      type: "warning"
    })
    return
  }
  if(!SOCKETIO.value){
    Snackbar({
      content: "聊天服务连接失败！",
      duration: 1000,
      type: "warning"
    })
    return
  }
  if(!serviceInfo.value){
    if(autoAnswerStatus.value === 1){
      sendLssuesText()
      return
    }else{
      Snackbar({
        content: "当前系统未开启智能客服，请联系人工客服咨询！",
        duration: 1000,
        type: "warning"
      })
      return
    }
  }
  send(msg.value, 1)
}
//消息发送方法
const send = (inputMsg, msgType)=>{
  if(firstMsgNum.value === 0){ // 连接后第一条消息，通知客服，变更对话结束状态
    SOCKETIO.value.emit('newDialogue', {
      serviceId: serviceInfo.value._id
    })
  }
  firstMsgNum.value += 1
  const msgData = {
    type: msgType, // 消息类型-必传
    isService:1, // 是客服
    toUser: serviceInfo.value._id, // 发送给谁 - 必传
    msg: inputMsg,  //消息内容-必传，图片消息此处为图片上传后返回的地址
    appKey: userInfo.value.appKey, // 客服给用户发消息，必须带该参数
    extend: '',
    msgKey: uuidv4()
  }
  const inMsg = {
    appKey: userInfo.value.appKey,
    formID: userInfo.value._id,
    formName: userInfo.value.username,
    formUserOssType: "",
    formUserPhoto: userInfo.value.photo,
    msg: inputMsg,
    msgTime: Date.now(),
    msgType: msgType,
    read: 0,
    toID: serviceInfo.value._id,
    toName: serviceInfo.value.username,
    toUserOssType: '',
    toUserPhoto: serviceInfo.value.photo,
    msgKey:msgData.msgKey,
    readStatus:1, // 1发送中  2  已发出  3 已读
  }
  store.commit('socketMsg/setMsgList', inMsg)
  SOCKETIO.value.emit('msg', msgData)
  msg.value = ''
}
// 未连接客服之前，发送消息走智能回复通道
const sendLssuesText = ()=>{
  const inMsg = {
    appKey: userInfo.value.appKey,
    formID: userInfo.value._id,
    formName: userInfo.value.username,
    msg: msg.value,
    msgTime: Date.now(),
    msgType: 1,
  }
  store.commit('socketMsg/setMsgList', inMsg)
  const lssuesMsg = {
    query:msg.value,
    conversation_id:conversation_id.value||''
  }
  SOCKETIO.value.emit('hotIssues', lssuesMsg)
  msg.value = ''
}
// 获取在线客服
const onlineService = ()=>{
  if(!SOCKETIO.value){
    Snackbar({
      content: "聊天服务连接失败！",
      duration: 1000,
      type: "warning"
    })
    return
  }
  store.commit('socketMsg/setMsgList', {
    msgType: 'tips',
    msg: '已为您转接人工客服，请耐心等待。',
    msgTime: Date.now()
  })
  SOCKETIO.value.emit('onlineService',{
    mode: 3
  })
}
// 结束对话
const talkOver = ()=>{
  if(firstMsgNum.value) send('用户已关闭对话，对话结束。', 1)  // 没给客服发过消息，不文字消息通知客服
  SOCKETIO.value.emit('closeTalk',{
    userId: serviceInfo.value._id,
  })
  // store.commit('socketMsg/setMsgList', {
  //   msgType: 'tips',
  //   msg: '您已关闭对话，对话结束。',
  //   msgTime: Date.now()
  // })
  serviceInfo.value = null
  firstMsgNum.value = 0
}
// 设置缓存中未读消息为已读
const setRead = ()=>{
  if(noReadList.value && JSON.stringify(noReadList.value) !== '{}'){
    for (const user in noReadList.value) {
      const msgKeys = toRaw(noReadList.value[user])
      if(msgKeys && msgKeys.length){
        SOCKETIO.value.emit('setRead', {
          appKey: userInfo.value.appKey, // 应用appKey，必传
          user: user, // 要设置消息为已读的用户ID，取对话列表返回user字段，和客服对话无对话列表时传消息中的formID
          msgKeyList: msgKeys, // 必传。发送消息时生成得uuid.如果没有msgKey不能实现消息已读未读。
          isService: 1, // 是否和客服沟通（和客服沟通必传1，和用户聊天可不传）
        })
        store.commit('socketMsg/clearNoReadList')
      }
    }
  }
}
// 显示更多工具
const showTools = ()=>{
  toolsShow.value = !toolsShow.value
}
// 文件上传，发送图片消息
const fileChange = (e)=>{
  const file = e.target.files[0]
  if (file) {
    showLoading.value = true
    const formData = new FormData();
    formData.append('file', file);
    uploadFile(formData).then(res => {
      if (res.code === 0) {
        send(res.data, 2)
      } else {
        Snackbar({
          content: res.msg || '图片上传失败',
          duration: 1000,
          type: "error"
        })
      }
      fileEl.value.value = ''
      showLoading.value = false
    }).catch(err => {
      console.log(err)
      Snackbar({
        content: '图片上传失败',
        duration: 1000,
        type: "error"
      })
      fileEl.value.value = ''
      showLoading.value = false
    })
    showTools()
  }
}
const selectFile = ()=>{
  if(!serviceInfo.value){
    Snackbar({
      content: '当前为智能客服不能发送图片',
      duration: 1000,
      type: "warning"
    })
    return
  }
  fileEl.value.click()
}
</script>

<template>
  <div class="home">
    <div class="loading" v-if="!loadingStatus">
      <var-loading type="circle" color="#252c21" description="加载中..."/>
    </div>
    <div class="chat-content" v-else>
      <main-header/>
      <!--   chat   -->
      <div class="chat-window" ref="chatWindow">
        <div v-for="item of msgList" :key="item.msgKey">
          <hot-lssues v-if="item.msgType === 'hotIssues' && item.msg && item.msg.length" :chat-item="item.msg"/>
          <chat-text-item v-if="item.msgType === 1 || item.msgType === 5" :chat-item="item" :key="item.msgKey"/>
          <chat-img-item v-if="item.msgType === 2" :chat-item="item" />
          <evaluate-temp v-if="item.msgType === 'invitationEvaluation'" :chat-item="item"/>
          <p v-if="item.msgType === 'tips'" class="tips">{{item.msg}}</p>
        </div>
      </div>
      <!--   chat   -->
      <!--   chat-tools   -->
      <div class="chat-tools">
        <div class="btns">
<!--          <chat-button text="意见反馈" icon="icon_tousu.png"/>-->
          <chat-button @click="onlineService" v-if="!serviceInfo" text="人工客服" icon="kefu-icon.png" :style-obj="{marginLeft: '12px'}"/>
          <chat-button @click="talkOver"  v-else text="结束对话" icon="close-talk.png" :style-obj="{marginLeft: '12px'}"/>
          <chat-button @click="goAccount"  text="账号找回" icon="close-talk.png" :style-obj="{marginLeft: '12px'}"/>
        </div>
        <div class="input-row">
          <div class="more" @click="showTools">
            <img class="more-tool" src="@/assets/add-one.png" alt="">
          </div>
          <div class="textarea-box">
            <textarea
                ref="textareaRef"
                :placeholder="`请输入消息`"
                v-model="msg"
                @input="inputChange"
            >
            </textarea>
          </div>
          <span class="send" @click="sendMsg">发送</span>
        </div>
        <div class="tools-box" v-if="toolsShow">
          <div class="item" @click="selectFile">
            <img src="@/assets/xc-icon.png" alt="">
            <span>相册</span>
          </div>
        </div>
      </div>
    </div>
    <input ref="fileEl" class="file-input" type="file" @change="fileChange">
  </div>
  <div class="var-loading" v-if="showLoading">
    <var-loading type="circle" color="#00afef" size="large"/>
  </div>
</template>

<style lang="less" scoped>
.loading {
  height: 100vh;
  display: flex;
  align-self: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.chat-content {
  display: flex;
  flex-direction: column;
  background: #F8F9FA;
  height: 100vh;
}

.chat-window {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
  box-sizing: border-box;
  .tips{
    text-align: center;
    color: #83909D;
    font-size: 12px;
    font-weight: 400;
    padding-top: 20px;
  }
}

.chat-tools {
  width: 100%;
  background: #fff;
  padding: 8px 15px;
  box-sizing: border-box;
}

.btns {
  margin-bottom: 8px;
}

.input-row {
  display: flex;
  align-items: flex-start;
  padding: 4px 0;

  .more {
    width: 34px;
    height: 40px;
    display: flex;
    align-items: center;

    .more-tool {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }

  .textarea-box {
    flex: 1;
    height: auto;
    min-height: 42px;
    margin-right: 10px;
    background: #F8F9FA;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 0 15px;
    display: flex;
    align-items: center;

    textarea {
      width: 100%;
      height: 16px;
      border: none;
      background: none;
      font-size: 14px;
      user-select: none;
      outline: none;
      resize: none;
      overflow: hidden;
      box-sizing: border-box;
    }
  }

  .send {
    border-radius: 21px;
    background: linear-gradient(135deg, #2F88FF 0%, #43CCF8 100%), #D8D8D8;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    width: 72px;
    flex: 0 0 72px;
    height: 42px;
    line-height: 42px;
  }
}

.tools-box{
  display: flex;
  padding: 15px 0;
  .item{
    flex: 0 0 62px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      width: 62px;
      height: 62px;
      margin-bottom: 8px;
    }
    span{
      color: #83909D;
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.file-input{
  position: fixed;
  top: 0;
  left: 99999px;
  width: 1px;
  height: 1px;
}
.var-loading{
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
