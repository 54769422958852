<script setup>
import {useStore} from "vuex";
import {getCurrentDate} from "@/utils/func";
import {computed, defineProps, onMounted, ref} from "vue";
import kefuDefaultHead from "../assets/kefu-default-head.png"
import userHeaderImage from "@/components/userHeaderImage.vue";
import QrcodeVue from "qrcode.vue"

const props = defineProps({
  chatItem: {
    type: Object,
    default: () => {
    }
  }
})

const store = useStore()
const userInfo = computed(() => store.getters['user/userInfo'])

const loadKefuHeadStatus = ref(true)
const kefuLoadError = ()=>{
  loadKefuHeadStatus.value = false
}
const msgExtend = ref({})
const locaHref = ref('')
onMounted(()=>{
  locaHref.value = location.origin
  if(props.chatItem.extend && props.chatItem.extend !== '{}'){
    try{
      msgExtend.value = JSON.parse(props.chatItem.extend)
    }catch (e) {
      console.log(e)
    }
  }
})
</script>

<template>
  <div class="chat-text">
    <template v-if="props.chatItem.formID && props.chatItem.formID === userInfo._id">
      <div class="mine" style="display: flex; flex-direction: row; justify-content: flex-end; align-items: flex-start">
        <div>
          <p class="time">{{ getCurrentDate(props.chatItem.msgTime) }}</p>
          <div class="msg">
            <b v-if="props.chatItem.readStatus === 3">已读</b>
            <b v-if="props.chatItem.readStatus === 2">未读</b>
            <b v-if="props.chatItem.readStatus === 1">发送中</b>
            <div class="msg-text">
              <span v-html="props.chatItem.msg"></span>
            </div>
          </div>
        </div>
        <div class="head-img" style="flex: 0 0 38px">
          <user-header-image/>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="msg">
        <img v-if="loadKefuHeadStatus && props.chatItem.formUserPhoto" :src="props.chatItem.formUserPhoto" style="margin-right: 12px;" @error="kefuLoadError"  alt=""/>
        <img v-else :src="kefuDefaultHead" style="margin-right: 12px;"  alt=""/>
        <div>
          <p class="time">{{ getCurrentDate(props.chatItem.msgTime) }}</p>
          <div class="msg-text" style="display: flex; flex-direction: column">
            <span v-html="props.chatItem.msg"></span>
            <template v-if="props.chatItem.msgType === 5 && msgExtend._id">
                <br/>
                <qrcode-vue :value="`${locaHref}/#/workOrder?appkey=${msgExtend.appKey}&workid=${msgExtend._id}`" :size="80"></qrcode-vue>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<style scoped lang="less">
.chat-text {
  padding-left: 20px;
  padding-top: 20px;
}

.time {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-bottom: 6px;
}

.msg {
  flex: 1;
  display: flex;
  align-items: flex-start;

  img {
    width: 38px;
    flex: 0 0 38px;
    height: 38px;
    border-radius: 100%;
    margin-right: 12px;
  }

  .msg-text {
    background: #D1E4FF;
    border-radius: 10px;
    max-width: 80%;
    padding: 10px;
    min-height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  span {
    word-break: break-all;
    font-size: 14px;
  }
}

.mine {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .time {
    padding-right: 20px;
  }

  .msg {
    display: flex;
    justify-content: flex-end;
    max-width: 100%;

    .msg-text {
      margin-right: 6px;
      word-break: break-word;
    }

    b {
      font-size: 12px;
      color: #999;
      align-self: flex-end;
      margin-right: 5px;
      font-weight: 400;
    }
  }
}
</style>
