<script setup>
import {getCurrentDate} from "@/utils/func";
import {useStore} from "vuex";
import {computed, defineProps, ref} from "vue";
import kefuDefaultHead from "@/assets/kefu-default-head.png";
import {ImagePreview} from "@varlet/ui";
import userHeaderImage from "@/components/userHeaderImage.vue";

const props = defineProps({
  chatItem: {
    type: Object,
    default: () => {
    }
  }
})

const store = useStore()
const userInfo = computed(()=> store.getters['user/userInfo'])

const loadKefuHeadStatus = ref(true)
const kefuLoadError = ()=>{
  loadKefuHeadStatus.value = false
}

function preview(e) {
  ImagePreview(e.target.currentSrc)
}
</script>

<template>
  <div class="chat-text">
    <template v-if="props.chatItem.formID && props.chatItem.formID === userInfo._id">
      <div class="mine" style="display: flex; flex-direction: row; justify-content: flex-end; align-items: flex-start">
        <div>
          <p class="time" style="text-align: right">{{getCurrentDate(props.chatItem.msgTime)}}</p>
          <div class="msg">
            <b v-if="props.chatItem.readStatus === 3">已读</b>
            <b v-if="props.chatItem.readStatus === 2">未读</b>
            <b v-if="props.chatItem.readStatus === 1">发送中</b>
            <div class="msg-text">
              <var-image
                  @click="preview"
                  style="max-width: 300px"
                  :src="props.chatItem.msg"
              >
                <template #error>
                  <svg viewBox="0 0 24 24" style="width: 100px; height: 100px;">
                    <path
                        fill="currentColor"
                        d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41"
                    >
                    </path>
                  </svg>
                </template>
              </var-image>
            </div>
          </div>
        </div>
        <div class="head-img" style="flex: 0 0 38px">
          <user-header-image/>
        </div>
      </div>
    </template>
    <template v-else>
      <p class="time">{{getCurrentDate(props.chatItem.msgTime)}}</p>
      <div class="msg">
        <img v-if="loadKefuHeadStatus && props.chatItem.formUserPhoto" :src="props.chatItem.formUserPhoto" style="margin-right: 12px;" @error="kefuLoadError"  alt=""/>
        <img v-else :src="kefuDefaultHead" style="margin-right: 12px;"  alt=""/>
        <div class="msg-text">
          <var-image
              @click="preview"
              style="max-width: 300px"
              :src="props.chatItem.msg"
          >
            <template #error>
              <svg viewBox="0 0 24 24" style="width: 100px; height: 100px;">
                <path
                    fill="currentColor"
                    d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41"
                >
                </path>
              </svg>
            </template>
          </var-image>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="less">
.chat-text{
  padding-left: 20px;
  padding-top: 20px;
}
.time{
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-bottom: 6px;
}
.msg{
  display: flex;
  align-items: flex-start;
  img{
    width: 50px;
    flex: 0 0 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 12px;
  }
  .msg-text{
    background: #D1E4FF;
    border-radius: 4px;
    max-width: 60%;
    padding: 10px;
    min-height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  span{
    font-size: 14px;
  }
  img{
    max-width: 100%;
  }
}
.mine{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .time{
    padding-right: 20px;
  }
  .msg{
    display: flex;
    justify-content: flex-end;
    .msg-text{
      margin-right: 6px;
    }
    img{
      max-width: 100%;
    }
    b{
      font-size: 12px;
      color: #999;
      align-self: flex-end;
      margin-right: 5px;
      font-weight: 400;
    }
  }
}
</style>
