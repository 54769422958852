const isDev = process.env.NODE_ENV === 'development'

// 生产环境
const production = {
    WS_URL: "wss://chat.webtest.vip",
    PROXY_NAME: "/api",
    PROXY_API: "http://chat.webtest.vip"
}

// 开发环境
const development = {
    WS_URL: "wss://chat.webtest.vip",
    PROXY_NAME: "/api",
    PROXY_API: "http://chat.webtest.vip"
}

module.exports = isDev?development:production
