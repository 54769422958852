<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {orderDetails} from "@/api/base";
import {getCurrentDate} from "@/utils/func"
import { ImagePreview } from '@varlet/ui'

onMounted(()=>{
  const searchParams = useRoute().query
  if(searchParams.workid){
    getOrderInfo(searchParams.workid)
  }
})

const orderWorkInfo = ref({})
const getOrderInfo = async (workid)=>{
  try{
    const info = await orderDetails({_id: workid})
    if(info.code === 0) {
      const infoData = info.data
      if(infoData.flowPath && infoData.flowPath.length){
        infoData.flowPath = infoData.flowPath.map(item=>{
          item.extendData = JSON.parse(item.extendData)
          return item
        })
      }
      orderWorkInfo.value = infoData
    }
  }catch (e) {
    console.log(e)
  }
}

function preview(img) {
  ImagePreview(img)
}

const returnWorkStatusText = (row)=>{
  switch (row.status){
    case 1:
      return '待分配'
    case 2:
      return '待处理'
    case 3:
      return '已解决'
    case 4:
      return '已关闭'
    case 5:
      return '已暂停'
    case 6:
      return '重新打开'
  }
}

//
const collapseValue = ref(['1'])
</script>

<template>
  <div class="details">
    <var-app-bar title="⚪ 工单详情" color="linear-gradient(90deg, rgba(72,176,221,1) 0%, rgba(0,208,161,1) 100%)"/>
    <div class="details-main">
      <var-cell>处理状态：<b>{{returnWorkStatusText(orderWorkInfo)}}</b></var-cell>
      <var-cell>工单ID：<b>{{orderWorkInfo._id}}</b></var-cell>
      <var-cell>工单类型：<b>{{orderWorkInfo.className}}</b></var-cell>
      <var-cell>创建人：<b>{{orderWorkInfo.createUserName}}</b></var-cell>
      <var-cell>创建时间：<b>{{getCurrentDate(orderWorkInfo.createTime)}}</b></var-cell>
      <var-cell>当前经办人：<b>{{orderWorkInfo.processedUserNickname || orderWorkInfo.processedName || '待分配处理人' }}</b></var-cell>
      <var-cell>工单描述：<b>{{orderWorkInfo.describe || '-'}}</b></var-cell>
      <var-cell>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap">
          <p style="flex: 0 0 70px">工单图片：</p>
          <div v-if="orderWorkInfo.imgs">
            <var-space :size="[20, 20]">
              <var-image
                  v-for="(item,index) of orderWorkInfo.imgs.split(',')"
                  :key="index" width="100px"
                  :radius="3"
                  lazy
                  ripple
                  @click="preview(item)"
                  :src="item"
                  :loading="require('../assets/loading.gif')"
              >
                <template #error>
                  <svg viewBox="0 0 24 24" style="width: 100%; height: 100%;">
                    <path
                        fill="currentColor"
                        d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41"
                    >
                    </path>
                  </svg>
                </template>
              </var-image>
            </var-space>
          </div>
          <b v-else>-</b>
        </div>
      </var-cell>
    </div>
    <div style="padding: 10px" v-if="orderWorkInfo.flowPath">
      <var-collapse v-model="collapseValue">
        <var-collapse-item title="处理进度" name="1">
          <p class="work-order-item" v-for="(item,index) of orderWorkInfo.flowPath.reverse()" :key="index">
            <span style="margin-bottom: 5px; display: block; font-weight: bold"><span style="color: #2F88FF; font-size: 20px">▸</span> {{getCurrentDate(item.createTime)}}</span>
            <span>{{item.title}}</span>
            <span style="display: block; background: rgba(221,221,221,0.31); margin-right: 10px; padding: 5px; border-radius: 5px" v-if="item.extendData && item.extendData.describe">
              <span style="font-weight: bold; padding-bottom: 3px; display: block">处理详情:</span>
              <span style="display: block; margin-bottom: 3px">{{item.extendData.describe}}</span>
              <var-image
                  @click="preview(imgItem)"
                  :radius="3"
                  style="width: 60px; margin-right: 5px"
                  v-for="imgItem of item.extendData.imgs.split(',')"
                  :key="imgItem"
                  :src="imgItem" alt=""
                  :loading="require('../assets/loading.gif')"
              >
            </var-image>
            </span>
          </p>
        </var-collapse-item>
      </var-collapse>
    </div>
  </div>
</template>

<style scoped lang="less">
.details{

}
.details-main{
  margin: 10px;
  box-shadow: 0 3px 1px -2px var(--shadow-key-umbra-opacity), 0 2px 2px 0 var(--shadow-key-penumbra-opacity), 0 1px 5px 0 var(--shadow-key-ambient-opacity);;
}
.work-order-item{
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  &:last-child{
    border-bottom: none;
  }
}
</style>
