import axios from 'axios'
import store from '@/store'
import {Snackbar} from "@varlet/ui";


const service = axios.create({
    baseURL: '/api',
    timeout: 30000, // 请求超时时间，设置15秒。有些接口事务多能会长
})

// 请求发起拦截
service.interceptors.request.use(
    (config) => {
        const token = store.getters['user/uiserImToken']
        if(token) config.headers.authorization = token
        if (config.method === 'get') {
            // 统一增加时间戳
            //config.params['d'] = new Date().getTime()
        }
        return config
    },
    (error) => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    },
)

// 请求响应拦截
service.interceptors.response.use(
    (response) => {
        const res = response.data
        if (res.code !== 0) {
            if (res.code === 401) {
                Snackbar({
                    content:"登录信息失效，即将刷新页面",
                    duration: 1500,
                    type:"warning"
                })
                setTimeout(()=>{
                    location.reload()
                },1800)
            } else if (res.code === 500) {
                Snackbar({
                    content: '程序开小差了，请稍后再试！',
                    type: 'error',
                    duration: 5 * 1000,
                })
            } else {
                console.log(res)
                Snackbar({
                    content: `ERROR: ${res.msg}` || 'Error',
                    type: 'error',
                    duration: 5 * 1000,
                })
            }
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    (error) => {
        let { message } = error
        if (message === 'Network Error') {
            message = '接口连接异常'
        } else if (message.includes('timeout')) {
            message = '系统接口请求超时'
        } else if (message.includes('Request failed with status code')) {
            message = '系统接口' + message.substr(message.length - 3) + '异常'
        }
        Snackbar({
            content: message,
            type: 'error',
            duration: 5 * 1000,
        })
        return Promise.reject(error)
    },
)

export default service
