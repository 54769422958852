<script setup>
import {computed, ref, defineProps, onMounted} from "vue";
import {useStore} from "vuex";

const store = useStore()
const activeId = ref(null)
const evalData = [
  {id:1, img: require('../assets/e-1.png'), activeImg: require('../assets/e-1-s.png'), title: '不满意'},
  {id:2, img: require('../assets/e-2.png'), activeImg: require('../assets/e-2-s.png'), title: '一般'},
  {id:3, img: require('../assets/e-3.png'), activeImg: require('../assets/e-3-s.png'), title: '满意'},
  {id:4, img: require('../assets/e-4.png'), activeImg: require('../assets/e-4-s.png'), title: '很满意'},
  {id:5, img: require('../assets/e-5.png'), activeImg: require('../assets/e-5-s.png'), title: '非常满意'},
]
const SOCKETIO = computed(()=> store.state.socketMsg.socketIO)
const props = defineProps({
  chatItem:{
    type: Object,
    default:()=>{}
  }
})

onMounted(()=>{
  if(props.chatItem.score){
    activeId.value = props.chatItem.score
  }
})

const tapItem = (id)=>{
  if(props.chatItem.evalStatus === 1) return
  activeId.value = id
}

const serviceRating = ()=>{
  if(!activeId.value) return
  SOCKETIO.value.emit('serviceRating',{
    serviceId: props.chatItem.serviceInfo._id,
    score: activeId.value,
    msgKey: props.chatItem.msgKey
  })
}
</script>

<template>
  <div class="eval">
    <div class="evals">
      <p class="title">您对客服{{props.chatItem.serviceInfo.nickname}}的服务满意吗？</p>
      <div class="list">
        <div class="eval-item" v-for="item of evalData" :key="item.id" @click="tapItem(item.id)">
          <img v-if="activeId === item.id" :src="item.activeImg" alt="">
          <img v-else :src="item.img" alt="">
          <span>{{item.title}}</span>
        </div>
      </div>
      <div class="btn">
        <span
            v-if="props.chatItem.evalStatus === 0"
            @click="serviceRating"
            :class="{'disable': !props.chatItem.serviceInfo || !props.chatItem.serviceInfo._id}"
        >
          评价
        </span>
        <span
            v-else
            class="disable"
        >
          评价成功
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.eval {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  .evals {
    width: 90%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #fff;
    padding: 14px 20px;
    box-sizing: border-box;
    .title{
      text-align: center;
      color: #1C212A;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      span{
        flex: 0 0 110px;
        height: 34px;
        text-align: center;
        line-height: 34px;
        border: 1px solid #2F88FF;
        border-radius: 18px;
        color: #2F88FF;
        font-size: 14px;
        &.disable{
          border: 1px solid #ccc;
          color: #ccc;
        }
      }
    }
  }

  .list {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  .eval-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 40px;
      height: 44px;
      margin-bottom: 9px;
    }

    span {
      color: #1C212A;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
    }
  }
}
</style>
