// 获取当前链接参数
export function getQueryParams() {
    let queryParams = {};
    const searchParams = new URLSearchParams(window.location.search);
    // 遍历查询参数
    searchParams.forEach(function(value, key) {
        queryParams[key] = value;
    });
    return queryParams;
}

// 返回本地日期或格式化传入日期
export function getCurrentDate (time=null, validate = true, separator = ['-', ':']) {
    let currentTimeObj = time?new Date(time):new Date();
    let year = currentTimeObj.getFullYear();
    let month = (currentTimeObj.getMonth() + 1);
    month = month >= 10 ? month : '0' + month;
    let day = currentTimeObj.getDate();
    day = day >= 10 ? day : '0' + day;
    let hour = currentTimeObj.getHours();
    hour = hour >= 10 ? hour : '0' + hour;
    let minute = currentTimeObj.getMinutes();
    minute = minute >= 10 ? minute : '0' + minute;
    let second = currentTimeObj.getSeconds();
    second = second >= 10 ? second : '0' + second;
    if (validate) {
        let ymd = [year, month, day];
        let his = [hour, minute, second];
        return ymd.join(separator[0]) + ' ' + his.join(separator[1]);
    } else {
        let ymd = [year, month, day];
        return ymd.join(separator[0]);
    }
}

// 去除字符串中的所有空格
export function removeSpaces(str) {
    if (str.trim() === "") {
        return "";
    } else {
        return str.replace(/\s/g, "");
    }
}
// 
export const isUrl = (str) => {
    const pattern = new RegExp('^(http?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?'+ // port
        '(\\/[-a-z\\d%_.~+]*)*'+ // path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}
